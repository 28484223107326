<template>
  <v-container>
    <div class="info__container">
      <div class="info__agree">
        <div class="subject mb-2">[필수] 축산물품질평가원 개인정보 제 3자 제공에 관한 동의 안내</div>
  
        <div class="content">1. 개인정보를 제공받는자 : 주식회사원스프링</div>
        <div class="content">2. 개인정보를 제공받는자의 개인정보 이용 목적 : 고객관리 및 서비스 제공</div>
        <div class="content">3. 제공하는 개인정보 항목 : 성명, 농장명, 돼지등급판별(확인서) 정보 등</div>
        <div class="content">4. 개인정보를 제공받는 자의 개인정보 보유 및 이용기간 : 제공 후 1년</div>
        <div class="content">5. 동의를 거부할 수 있으며, 동의 거부 시 해당 서비스는 제공되지 않습니다.</div>
      </div>

      <v-row justify="center">
        <v-checkbox v-model="checkbox" color="#3a4f3f" label="동의합니다."></v-checkbox>
      </v-row>

      <div class="certify__contaner" v-show="checkbox == true">
        <!-- <div class="certify__content">
          <v-subheader class="info_title">사업자등록번호</v-subheader> 
          <v-text-field dense outlined clearable class="selectbox shrink"
            id="userId" 
            ref="userId"
            v-model="userId"
            @keyup="getRegMask(userId)" 
            color="#3a4f3f"
          ></v-text-field>
        </div> -->

        <div class="certify__content">
          <v-subheader class="info_title">농장명</v-subheader> 
          <v-text-field dense outlined clearable class="selectbox shrink"
            id="userNm" 
            ref="userNm" 
            v-model="userNm"
            placeholder="mtrace 회원정보 명과 동일한 이름"
            color="#3a4f3f"
          ></v-text-field>

          <!-- <v-btn class="certify_btn" style="width:95px;"
            id="mtraceVerifyNo" 
            ref="mtraceVerifyNo" 
            @click="mtraceVerifyNo()"
            color="#3a4f3f">
            인증번호 받기
          </v-btn> -->
        </div>

        <div class="certify__content">
          <v-subheader class="info_title">핸드폰번호</v-subheader> 
          <v-text-field dense outlined clearable class="selectbox shrink mr-2"
            id="userMtno" 
            ref="userMtno" 
            v-model="userMtno"
            @keyup="getPhoneMask(userMtno)"
            placeholder="mtrace에 등록된 동일한 번호"
            color="#3a4f3f"
          ></v-text-field>

          <v-btn class="certify_btn" style="width:95px;"
            id="mtraceVerifyNo" 
            ref="mtraceVerifyNo" 
            @click="mtraceVerifyNo()"
            color="#3a4f3f">
            인증번호 받기
          </v-btn>
        </div>

        <div class="certify__content" v-if="show">
          <v-subheader class="info_title">인증번호</v-subheader> 
          <v-text-field dense outlined clearable class="selectbox shrink mr-1"
            type="number"
            id="verifyNo" 
            ref="verifyNo" 
            v-model="verifyNo"
            color="#3a4f3f"
          ></v-text-field>

          <div class="info_time">{{ TimerStr }}</div>
          <v-btn @click="mtraceNumCheck()" class="certify_btn mb-mr" style="width:40px;">인증확인</v-btn>
        </div>

        <div class="mb_info_time" v-if="show">{{ TimerStr }}</div>

        <template>
          <div class="text-end mt-3">
            <div
            color="primary"
            class="del_btn"
            @click="dialog = true"
            >
            정보제공철회
            </div>
          </div>

            <v-dialog
              v-model="dialog"
              width="450"
            >
              <v-card  style="padding:35px; border-radius: 30px;">
                <!-- <div class="certify__content">
                  <v-subheader class="info_title">사업자등록번호</v-subheader> 
                  <v-text-field dense outlined clearable class="selectbox shrink"
                    id="userId" 
                    ref="userId"
                    v-model="userId"
                    @keyup="getRegMask(userId)" 
                    color="#3a4f3f"
                  ></v-text-field>
                </div> -->

                <div class="certify__content">
                  <v-subheader class="info_title">농장명</v-subheader> 
                  <v-text-field dense outlined clearable class="selectbox shrink"
                    id="userNm" 
                    ref="userNm" 
                    v-model="userNm"
                    placeholder="사업자등록증과 동일한 이름"
                    color="#3a4f3f"
                  ></v-text-field>
                </div>

                <div class="certify__content">
                  <v-subheader class="info_title">핸드폰번호</v-subheader> 
                  <v-text-field dense outlined clearable class="selectbox shrink"
                    id="userMtno" 
                    ref="userMtno" 
                    v-model="userMtno"
                    @keyup="getPhoneMask(userMtno)"
                    placeholder="이력관리시스템에 등록된 동일한 번호"
                    color="#3a4f3f"
                  ></v-text-field>

                </div>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn @click="dialog = false" class="certify_btn" style="background:white !important; color:#3a4f3f;">닫기</v-btn>
                  <v-btn @click="deleteUseAgree()" class="certify_btn">정보제공철회</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
        </template>
      </div>
    </div>
  </v-container>
</template>

<script>
import Common from "@/utils/custom/common.js";
import Apis from '@/api/apis'

export default {
  name: 'MtraceAgree',

  data: () => ({
  checkbox:false,
  show: false,

  Timer: null,
  TimeCounter: 60,
  TimerStr: "01:00",

  // userId: "",
  userNm:"",
  userMtno:"",
  verifyNo:"",

  dialog : false,
  }),
  created(){

  },
  methods:{
    mtraceVerifyNo(){
      Apis.mtraceVerifyNo({
        userId: "8808800627",
        apiKey: "HCDUqoybMpbuyWKRHUQY",
        userNm: this.userNm.replace(/ /g, ''),
        userMtno: this.userMtno.replace(/[^0-9]/g, ''),
        lvstckKnd: 2,
        },(res) => {
          // console.log(res);  
          // console.log(res.header.resultCode);  
          // console.log(res.header.resultMsg);  

          if (res.header.resultCode == 'INFO_0000' ){
            this.show = true;
            this.Timer = this.timerStart(this.TimeCounter);
          } else if(res.header.resultCode == 'ERROR_1001'){
            alert('축산물이력제 정보와 일치하지 않습니다.')
          } else {
            // this.clearData();
            alert('openAPI 사용자가 아닙니다. 서비스를 신청해주세요.');
          }
        }).catch( (err) => {  // API 오류 처리
            console.log(" mtraceVerifyNo API 호출 오류",err)
            alert(err);
        })
    },
    mtraceNumCheck(){
      Apis.userHistInfoSvcUseAgreeReq({
        userId: "8808800627",
        apiKey: "HCDUqoybMpbuyWKRHUQY",
        userNm: this.userNm.replace(/ /g, ''),
        userMtno: this.userMtno.replace(/[^0-9]/g, ''),
        verifyNo: this.verifyNo.replace(/[^0-9]/g, ''),
        lvstckKnd: 2,
        svcGubunCd: "991008" || "991011" 
        },(res) => {
          console.log(res);  
          // console.log(res.header.resultCode);  
          // console.log(res.header.resultMsg);

          if(res.header.resultCode == "INFO_0000"){
            clearInterval(this.Timer);
            alert('인증되었습니다.');
            window.close();
          } else if(res.header.resultCode == 'ERROR_2001'){
            alert('활용동의 처리 중 오류가 발생했습니다. 인증을 다시 해 주세요.');
            this.Timer = this.setTime(this.Timer);
          } else if (res.header.resultCode == 'ERROR_1001' && res.header.resultMsg == '유효시간(1분)이 초과된 인증번호입니다.'){
            alert('유효시간이 초과된 인증번호입니다. 인증을 다시 진행해주세요.');
            this.Timer = this.setTime(this.Timer);
          } else {
            alert('인증번호가 일치하지 않습니다. 인증번호를 확인해주세요.');
            this.verifyNo = "";
          }
        })
    },
    deleteUseAgree(){
      Apis.deleteUserHistInfoSvcUseAgree({
        userId: "8808800627",
        apiKey: "HCDUqoybMpbuyWKRHUQY",
        userNm: this.userNm.replace(/ /g, ''),
        userMtno: this.userMtno.replace(/[^0-9]/g, ''),
        verifyNo: this.verifyNo.replace(/[^0-9]/g, ''),
        lvstckKnd: 2,
        svcGubunCd: "991999" 
        },(res) => {
          // console.log(res);  
          // console.log(res.header.resultCode);  
          // console.log(res.header.resultMsg);

          if(res.header.resultCode == "INFO_0000"){
            alert('정상적으로 정보제공철회를 완료했습니다.');
            window.close();
          } else {
            alert('활용동의 삭제 처리 중 오류가 발생했습니다.');
            this.dialog = false;
            this.clearData();
          }
        })
    },
    clearData() {
      this.userId = ""
      this.userNm = ""
      this.userMtno = ""
      this.verifyNo = ""
    },
    timerStart() {
      this.TimeCounter = 60;

      // 1초에 한번씩 start 호출
      var interval = setInterval(() => {
        this.TimeCounter--; //1초씩 감소
        this.TimerStr = this.prettyTime();

        if (this.TimeCounter <= 0){
          this.timerStop(interval);
        } 
        // console.log(this.Timer, "timer", this.TimeCounter, "time")
      }, 1000);
      return interval;
    },
    setTime(Timer){
      clearInterval(Timer);
      this.TimeCounter = 60;
      this.TimerStr = this.prettyTime();

      this.verifyNo = "";
      this.show = false; 
    },
    timerStop(Timer) {
      clearInterval(Timer);
      this.TimeCounter = 0;

      if(this.TimeCounter == 0){
        alert('인증시간이 초과되었습니다. 인증을 다시 진행해주세요.');
      }
      this.show=false;
    },
    prettyTime() {
      // 시간 형식으로 변환 리턴
      let time = this.TimeCounter / 60;
      let minutes = parseInt(time);
      let secondes = Math.round((time - minutes) * 60);
      return (
        minutes.toString().padStart(2, "0") +
        ":" +
        secondes.toString().padStart(2, "0")
      );
    },
    getPhoneMask(val) {
      this.userMtno = Common.getMask(val)
    },

    getRegMask(val) {
      this.userId = Common.getRegMask(val)
    },

  }
}
</script>

<style scoped  lang="scss">
*{
  letter-spacing: -1.75px;
}
.subject{
  font-size:18px;
  font-weight:600;
  color:#5f5f5f;
  text-align:center;
}
.content{
  font-size:13px;
  font-weight:400;
  color:#5f5f5f;
}
.info__container{
  margin:20px;
}
.info__agree{
  width:100%;
  border:1px solid #939393;
  border-radius: 20px;
  padding:20px;
}
.info_title{
  width:120px;
  font-size:16px;
  color:black;
}
.info_time{
  letter-spacing: -0.5px;
  font-weight:600;
  font-size:13px;
  width:40px;
  height:20px;
  line-height:38px;
}
.selectbox{
  height:30px;
  width:200px;
  margin-right:30px;
}
.certify__contaner{
  margin-top:30px;
  margin-left:10px;
}
.certify__content{
  display:flex;
}
.certify_btn{
  background-color: #3a4f3f !important;
  color:white;
  border-radius: 20px;
  letter-spacing: -2px;
}
.del_btn{
  cursor: pointer;
  font-size:14px;
  margin-right:20px;
  color:#5f5f5f;
}
::v-deep .selectbox input::placeholder {
  font-size:14px;
  letter-spacing: -1px;
}
.mb_info_time{
  display:none;
}

@media (max-width: 400px) {
  .info_title{
    width:80px;
    font-size:13px;
  }
  .selectbox{
    max-width:165px !important;
  }
  .certify_btn{
    font-size:0.7rem;
    width:40px !important;
  }
  ::v-deep .selectbox input::placeholder {
  font-size:11px;
  letter-spacing: -1px;
  }
  ::v-deep .v-text-field__slot{
  font-size:14px !important;
  letter-spacing: -1px;
  }
  .info_time{
    display:none;
  }
  .mb_info_time{
  display:block;
  font-size:15px;
  color:#5f5f5f;
  }
  .mb_info_time{
    margin-left:80px;
    margin-top:-5px;
  }
  .mb-mr{
    margin-left:5px;
  }
}

</style>
